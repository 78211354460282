import React from "react";
import { Container } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import { COLOR } from "../../style";
import {
  TitleLight,
  SubheaderLight,
} from "../../styleguide/components/Typography";

import { bg1, step1, step2, step3 } from "./images";

const ProcessBackground = styled.div`
  background-color: ${COLOR.PRIMARY};
  background-image: url(${bg1});
  text-align: center;
  background-size: cover;
`;

const ProcessInner = styled(Container)`
  padding: 80px 0 40px;
`;

const StepsImg = styled.img`
  max-width: 75%;
  margin-top: 0px;
`;

export function Process() {
  return (
    <ProcessBackground>
      <ProcessInner>
        <TitleLight
          style={{ color: COLOR.SECONDARY, fontSize: 35, fontWeight: 600 }}
        >
          How does it work?
        </TitleLight>
        <SubheaderLight
          style={{ fontSize: 20, fontWeight: 600, marginBottom: 7 }}
        >
          It couldn't be simpler!
        </SubheaderLight>
        <SubheaderLight>Make your will in three easy steps.</SubheaderLight>
        <div style={{ margin: "50px auto", maxWidth: 700 }}>
          <LazyLoad height={300} once>
            <StepsImg
              alt="1. Complete an online form"
              src={step1}
              style={{ marginRight: "10%" }}
            />
          </LazyLoad>
          <LazyLoad height={300} once>
            <StepsImg
              alt="2. We'll draft the will and get approval from scholars and solicitors"
              src={step2}
              width="100%"
              style={{ marginLeft: "10%" }}
            />
          </LazyLoad>
          <LazyLoad height={200} once>
            <StepsImg
              width="100%"
              alt="3. Print it... Sign it... Done."
              src={step3}
            />
          </LazyLoad>
        </div>
      </ProcessInner>
    </ProcessBackground>
  );
}
