import React from "react";
import { ResponsiveEmbed } from "react-bootstrap";
import styled from "styled-components";
import { COLOR } from "../../style";
import {
  SubheaderLight,
  Subheader,
  Body1,
  Body1Light,
} from "../../styleguide/components/Typography";

interface CardProps {
  Icon?: Function;
  src?: string;
  title: string;
  description: string;
  color?: "light" | "dark";
}

const SimpleCardContainer = styled.div`
  display: flex;
  text-align: left;
`;

const StackedCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export function SimpleCard({ Icon, title, description, color }: CardProps) {
  const isLight = color === "light";

  return (
    <SimpleCardContainer>
      {Icon && (
        <Icon
          color={isLight ? COLOR.WHITE : COLOR.PRIMARY}
          size={40}
          style={{ marginRight: "20px", minWidth: "40px" }}
        />
      )}
      <div>
        {isLight ? (
          <SubheaderLight>{title}</SubheaderLight>
        ) : (
          <Subheader>{title}</Subheader>
        )}
        <div style={{ marginTop: "4px" }}>
          {isLight ? (
            <Body1Light>{description}</Body1Light>
          ) : (
            <Body1>{description}</Body1>
          )}
        </div>
      </div>
    </SimpleCardContainer>
  );
}

export function FeatureCard({
  Icon,
  src,
  title,
  description,
  color,
}: CardProps) {
  const isLight = color === "light";

  return (
    <StackedCardContainer>
      <div style={{ marginBottom: "10px" }}>
        {Icon && (
          <Icon color={isLight ? COLOR.WHITE : COLOR.PRIMARY} size={40} />
        )}
        {src && (
          <ResponsiveEmbed aspectRatio="1by1">
            <div
              style={{
                backgroundImage: `url("${src}")`,
                backgroundSize: "cover",
                height: "100%",
                width: "100%,",
              }}
            />
          </ResponsiveEmbed>
        )}
      </div>
      <div>
        {isLight ? (
          <SubheaderLight>{title}</SubheaderLight>
        ) : (
          <Subheader
            style={{ color: COLOR.PRIMARY, fontSize: 20, lineHeight: '20px', fontWeight: 600 }}
          >
            {title}
          </Subheader>
        )}
        <hr style={{borderColor: COLOR.TERTIARY, width: 40}} />
        <div>
          {isLight ? (
            <Body1Light>{description}</Body1Light>
          ) : (
            <Body1 style={{ color: COLOR.PRIMARY }}>{description}</Body1>
          )}
        </div>
      </div>
    </StackedCardContainer>
  );
}
