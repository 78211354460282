import React from 'react';
import styled from 'styled-components';
import { DEVICE } from '../../style';

let idx = 0;

export const WavesSCG = styled.svg`
  position:relative;
  width: 100%;
  margin-bottom:-7px; /*Fix for safari gap*/
  max-height:150px;
  height:40px;
  min-height:40px;

  @media ${DEVICE.MD} {
    min-height:100px;
    height:15vh;
  }

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  .parallax > use:nth-child(5) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(6) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(7) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(8) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }  
`;

interface WavesProps {
  baseColor?: string;
  secondaryColor?: string;
}

export function Waves({baseColor = '#FFFFFF', secondaryColor}: WavesProps) {
  const [uuid] = React.useState('gentle-wave-' + idx++);

  return (
    <WavesSCG
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
      shape-rendering="auto">
      <defs>
        <path id={uuid} d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        <path id={`${uuid}1`} d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use xlinkHref={`#${uuid}1`} x="48" y="5" fill={`${secondaryColor}${Math.round(0.3 * 255).toString(16)}`} />
        <use xlinkHref={`#${uuid}1`} x="48" y="7" fill={secondaryColor} />
        <use xlinkHref={`#${uuid}`} x="48" y="5" fill={`${baseColor}${Math.round(0.3 * 255).toString(16)}`} />
        <use xlinkHref={`#${uuid}`} x="48" y="7" fill={baseColor} />
      </g>
    </WavesSCG>
  );
}
