import React from "react";
import Container from "react-bootstrap/Container";
import Button from "../../styleguide/components/Button";
import { backgroundImage } from "./images";
import {
  HomePromoContainer,
  HomePromoInner,
  PromoTitle,
  PromoSubtitle,
} from "./style";
import { Curve } from "../Effects/Curve";
import { COLOR } from "../../style";

export function HomePromo() {
  return (
    <Curve isDown>
      <HomePromoContainer backgroundImage={backgroundImage}>
        <Container>
          <HomePromoInner>
            <PromoTitle>
              Leave Your
              <br />
              Legacy
              <span style={{ color: COLOR.SECONDARY, fontSize: '0.9em' }}>...</span>
            </PromoTitle>
            <PromoSubtitle>
              From £75 and a few minutes of your time,
              <br />
              you can receive a fully compliant Islamic will.
            </PromoSubtitle>
            <Button to="/wills-form" color="tertiary" size="large">
              Let's go!
            </Button>
          </HomePromoInner>
        </Container>
      </HomePromoContainer>
    </Curve>
  );
}

export default HomePromo;
