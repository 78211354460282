import React from "react";
import { COLOR } from "../../style";
import {
  Body1,
  Subheader,
} from "../../styleguide/components/Typography";
import {QuoteContainer, SpeechMark} from './Quote.style';

interface QuoteProps {
  quote: string;
  author: string;
}

export function Quote({ quote, author }: QuoteProps) {
  return (
    <QuoteContainer>
      <div
        style={{
          maxWidth: "75%",
          marginLeft: '25%',
          position: "relative",
        }}
      >
        <SpeechMark className="font-quote right">’</SpeechMark>
        <SpeechMark className="font-quote left">‘</SpeechMark>
        <Body1 style={{ fontSize: 24, fontWeight: 600, color: COLOR.PRIMARY }}>{quote}</Body1>
        <Subheader style={{ textAlign: "right" }}>
          ({author})
        </Subheader>
      </div>
    </QuoteContainer>
  );
}
