import React, { ReactElement } from "react";
import styled from "styled-components";

interface CurveProps {
  children: ReactElement;
  isDown?: boolean;
}

const CurveMask = styled.div`
  width: 100%;
  overflow: hidden;
`;
const CurveEffect = styled.div`
  position: relative;
  width: 120%;
  margin-left: -10%;
  margin-right: 10%;
  min-height: 150px;
  border-radius: 200vh 200vh 0% 0% / 20vh 20vh 0% 0%;
  -moz-border-radius: 200vh 200vh 0% 0% / 20vh 20vh 0% 0%;
  -webkit-border-radius: 200vh 200vh 0% 0% / 20vh 20vh 0% 0%;
  overflow: hidden;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); //fixes overflow:hidden for safari

  &.is-down {
    position: relative;
    @extend .curve-up;
    border-radius: 0% 0% 200vh 200vh / 0% 0% 20vh 20vh;
    -moz-border-radius: 0% 0% 200vh 200vh / 0% 0% 20vh 20vh;
    -webkit-border-radius: 0% 0% 200vh 200vh / 0% 0% 20vh 20vh;
  }
`;
const CurveContents = styled.div`
  padding-left: 5%;
  padding-right: 5%;
`;

export function Curve({ children, isDown }: CurveProps) {
  return (
    <CurveMask>
      <CurveEffect className={isDown ? "is-down" : ""}>
        <CurveContents>{children}</CurveContents>
      </CurveEffect>
    </CurveMask>
  );
}
