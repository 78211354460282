import React from "react";
import styled from "styled-components";
import { ChevronDown } from "react-bootstrap-icons";
import { COLOR } from "../../style";
import { Body1, Body2 } from "../../styleguide/components/Typography";

import FAQContent from "./faq.json";

const FAQContentTitle = styled(Body2)`
  margin: 0 0 0;
  border-bottom: 1px solid ${COLOR.SECONDARY};
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const FAQContentBody = styled.div`
  transition: 0.5s;
  height: 0px;
  overflow: hidden;

  &.is-open {
    padding: 10px 0;
    height: auto;
  }
`;

export function FAQ() {
  const [indexVisible, setIndexVisible] = React.useState<number | null>();
  const toggleContent = (index: number) => {
    if (indexVisible === index) {
      setIndexVisible(null);
    } else {
      setIndexVisible(index);
    }
  };

  return (
    <>
      {FAQContent.map((item, index) => {
        return (
          <div key={index}>
            <FAQContentTitle onClick={() => toggleContent(index)}>
              <span>{item.question}</span>
              <ChevronDown size="24" style={{ marginRight: "10px", minWidth: '50px' }} color={COLOR.TERTIARY} /> 
            </FAQContentTitle>
            <FAQContentBody className={indexVisible === index ? 'is-open' : ''}>
              {item.answer.map((answer, index) => (
                <Body1 key={index}>{answer}</Body1>
              ))}
            </FAQContentBody>
          </div>
        );
      })}
    </>
  );
}
