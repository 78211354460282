import { useRef, useLayoutEffect, useState } from "react";

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }: any) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(effect: any, deps: any[], element?: any, useWindow?: any, wait?: any) {
  const position = useRef(getScrollPosition({ useWindow }));
  const [throttleTimeout, setThrottleTimeout]: any = useState(null);

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    setThrottleTimeout(null);
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          setThrottleTimeout(setTimeout(callBack, wait));
        }
      } else {
        callBack();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
