import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppContext, { APIEnvironment } from "../../contexts/app";
import { StickyHeader } from "../../components/Header";
import Footer from "../../components/Footer";
import HomePromo from "../../components/HomePromo";
import CommingSoonHeaderPromo from "../../components/HomePromo/CommingSoonHeaderPromo";
import { FeatureCard } from "../../components/Card";
import { COLOR } from "../../style";
import {
  Body2Light,
  Headline,
  TitleLight,
} from "../../styleguide/components/Typography";
import { Quote } from "./Quote";
import { Waves } from "../../components/Effects/Waves";
import { FAQ } from "../../components/FAQ";
import { Process } from "./Process";
import { bg2, icon1, icon2, icon3, icon4, icon5 } from "./images";
import { Curve } from "../../components/Effects/Curve";

function HomePage() {
  const appContext = React.useContext(AppContext);
  const sectionRefs = React.useRef<{ [key: string]: HTMLElement | null }>({});
  const location = useLocation();
  const [isCommingSoon, setIsCommingSoon] = React.useState(false);

  useEffect(() => {
    if (
      appContext.state.apiEnvironment !== APIEnvironment.DEV &&
      /^(www\.)?myislamicwill\.co\.uk/.test(window.location.host)
    ) {
      setIsCommingSoon(false);
    }
  }, [setIsCommingSoon, appContext.state.apiEnvironment]);

  React.useEffect(() => {
    sectionRefs.current = {};
  }, []);
  React.useEffect(() => {
    const currentHash = location.hash;
    const ref = sectionRefs.current[currentHash];

    if (ref) {
      ref.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>
          Islamic Wills in 20 minutes. Fully UK tax-efficient Wills -{" "}
          {appContext.state.companyName}
        </title>
      </Helmet>

      <StickyHeader />
      {isCommingSoon ? <CommingSoonHeaderPromo /> : <HomePromo />}
      <Quote
        quote="It is the duty of a Muslim who has something which is to be given
        as a bequest not to have it for two nights without
        having his will written down regarding it."
        author="Sahih-al-Bukhari"
      />

      <section
        ref={(ref) => {
          sectionRefs.current["#process"] = ref;
        }}
      >
        <Process />
      </section>
      <Curve isDown>
        <section
          ref={(ref) => {
            sectionRefs.current["#benefits"] = ref;
          }}
          style={{ background: COLOR.WHITE }}
        >
          <Container
            style={{
              paddingTop: "70px",
              paddingBottom: "70px",
              textAlign: "center",
            }}
          >
            <Row style={{ marginBottom: "40px" }}>
              <Col>
                <Headline
                  style={{
                    color: COLOR.SECONDARY,
                    fontWeight: 600,
                    fontSize: 35,
                  }}
                >
                  Why Choose Us?
                </Headline>
              </Col>
            </Row>
            <Row>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <LazyLoad height={70} once>
                      <img
                        alt="Fulfil a Religious Duty"
                        height="70px"
                        src={icon1}
                      />
                    </LazyLoad>
                  )}
                  title="Fulfil a Religious Duty"
                  description="If you pass away without leaving a Will, you will be deemed intestate, and your wealth will be distributed according to UK intestacy laws. This will mean that your wealth will not be distributed in compliance with Islamic Law."
                />
              </Col>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <LazyLoad height={70} once>
                      <img alt="Gain Peace of Mind" height="70px" src={icon2} />
                    </LazyLoad>
                  )}
                  title="Gain Peace of Mind"
                  description="Writing a fully compliant will with us ensures your wishes will be followed and helps to avoid unnecessary disputes after you have passed on."
                />
              </Col>
              <Col md style={{ marginTop: "10px" }}>
                <FeatureCard
                  Icon={() => (
                    <LazyLoad height={70} once>
                      <img
                        alt="Save Time and Money"
                        height="70px"
                        src={icon3}
                      />
                    </LazyLoad>
                  )}
                  title="Save Time and Money"
                  description="We use smart technology to save you hundreds of pounds and hours of paperwork. Supported throughout by real experts, our will writing process is quick, simple, and relatively inexpensive."
                />
              </Col>
            </Row>
          </Container>
        </section>
      </Curve>

      <div
        style={{
          backgroundImage: `url(${bg2})`,
          marginTop: "-50px",
          height: "300px",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      ></div>
      <section>
        <div></div>
      </section>

      <section
        ref={(ref) => {
          sectionRefs.current["#about"] = ref;
        }}
        style={{
          backgroundColor: COLOR.PRIMARY,
          padding: "40px 0 30px",
        }}
      >
        <Container>
          <Row>
            <Col md={2}></Col>
            <Col md={5}>
              <TitleLight
                style={{ fontSize: 30, fontWeight: 600, marginBottom: "24px" }}
              >
                We're aiming high
                <span style={{ color: COLOR.SECONDARY }}>...</span>
              </TitleLight>
              <Body2Light>
                We’ve made it our mission to help every UK-based Muslim obtain a
                legal and sharia compliant will by 2030.
              </Body2Light>
              <Body2Light>
                Designate legal guardians for your children, decide how your
                wealth and property will be distributed, and plan gifts for your
                family and those closest to you.
              </Body2Light>
              <Body2Light>
                Continue to be a positive example by donating to a charity of
                your choice or by leaving advice and guidance for your children.
              </Body2Light>
            </Col>
            <Col md={3} xs="2">
              <LazyLoad height={150} once>
                <img
                  alt="Mission to help every UK-based Muslim obtain a legal and sharia compliant will"
                  src={icon4}
                  width="100%"
                />
              </LazyLoad>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        ref={(ref) => {
          sectionRefs.current["#faq"] = ref;
        }}
      >
        <div style={{ backgroundColor: COLOR.LIGHT }}>
          <Container style={{ paddingBottom: "20px" }}>
            <Row>
              <Col md={8} style={{ paddingTop: "60px" }}>
                <Headline
                  style={{
                    paddingBottom: "30px",
                    color: COLOR.PRIMARY,
                    fontWeight: 600,
                  }}
                >
                  Frequently Asked Questions
                </Headline>
                <FAQ />
              </Col>
              <Col md xs="2" style={{ paddingTop: "60px" }}>
                <div
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                  }}
                >
                  <LazyLoad height={70} once>
                    <img
                      alt="UK Islamic Will"
                      src={icon5}
                      width="100%"
                      style={{ alignSelf: "flex-end" }}
                    />
                  </LazyLoad>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={{ backgroundColor: COLOR.SECONDARY }}>
            <div style={{ transform: "rotate(180deg)" }}>
              <Waves baseColor={COLOR.LIGHT} secondaryColor={COLOR.TERTIARY} />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default HomePage;
