import styled from "styled-components";
import { COLOR, DEVICE } from "../../style";
import { banner2 } from "./images";

export const SpeechMark = styled.span`
  color: ${COLOR.TERTIARY};
  font-size: 80px;
  position: absolute;

  &.right {
    top: -20px;
    right: -10px;
    height: 40px;
  }

  &.left {
    bottom: 40px;
    left: -30px;
    height: 40px;
  }

  @media ${DEVICE.MD} {
    font-size: 150px;

    &.right {
      top: -50px;
      right: -40px;
    }
  
    &.left {
      top: -40px;
      left: -60px;
    }
  }

  @media ${DEVICE.LG} {
    font-size: 200px;

    &.right {
      top: -70px;
      right: -50px;
    }
  
    &.left {
      top: -60px;
      left: -70px;
    }
  }
`;

export const QuoteContainer = styled.div`
  background-image: url(${banner2});
  background-repeat: no-repeat;
  background-position: -150px bottom;
  backgroundSize: fill;
  padding: 120px 5% 60px;
  margin-top: -40px;

  @media ${DEVICE.MD} {
    background-position: -100px bottom;
  }
  @media ${DEVICE.LG} {
    background-position: left bottom;
  }
`;