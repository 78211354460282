interface DeviceToStringMap {
  SM: string;
  MD: string;
  LG: string;
  [key: string]: string;
}

function lighten(color: string, amount: number) {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

export const SCREEN_WIDTH: DeviceToStringMap = {
  SM: "375px",
  MD: "768px",
  LG: "1024px",
};

export const DEVICE: DeviceToStringMap = Object.keys(SCREEN_WIDTH).reduce(
  (acc, key: string) => ({
    ...acc,
    [key]: `(min-width: ${SCREEN_WIDTH[key]})`,
  }),
  {} as any
);

export const COLOR_PALATE = {
  MIW_PURPLE: '#532C6D',
  MIW_TEAL: '#32D8C2',
  MIW_RED: '#FD495C',
  DARK_SATE_BLUE: '#423F77',
  ENGLISH_VIOLET: '#503759',
  EASY_GRAY: '#3B3B3B',
}

export const COLOR = {
  PRIMARY: COLOR_PALATE.MIW_PURPLE,
  PRIMARY_LIGHT: lighten(COLOR_PALATE.MIW_PURPLE, 100),
  PRIMARY_DARK: lighten(COLOR_PALATE.MIW_PURPLE, -100),
  SECONDARY: COLOR_PALATE.MIW_TEAL,
  SECONDARY_LIGHT: lighten(COLOR_PALATE.MIW_TEAL, 100),
  SECONDARY_DARK: lighten(COLOR_PALATE.MIW_TEAL, -100),
  TERTIARY: COLOR_PALATE.MIW_RED,
  TERTIARY_LIGHT: lighten(COLOR_PALATE.MIW_RED, 100),
  TERTIARY_DARK: lighten(COLOR_PALATE.MIW_RED, -100),
  GRAY: COLOR_PALATE.EASY_GRAY,
  GRAY_lIGHT: lighten(COLOR_PALATE.EASY_GRAY, 50),
  WHITE: '#fff',
  LIGHT: lighten('#FFFFFF', -20),
  DARK: lighten('#000000', 20),
};
