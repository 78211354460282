import React from "react";
import Container from "react-bootstrap/Container";
import Helmet from "react-helmet";
import { backgroundImage } from "./images";
import {
  HomePromoContainer,
  HomePromoInner,
  PromoTitle,
  PromoSubtitle,
} from "./style";
import { Curve } from "../Effects/Curve";
import { COLOR } from "../../style";
import Button from "../../styleguide/components/Button";

export function CommingSoonHeaderPromo() {
  return (
    <Curve isDown>
      <HomePromoContainer backgroundImage={backgroundImage}>
        <Container>
          <HomePromoInner>
            <PromoTitle>
              Leave Your
              <br />
              Legacy
              <span style={{ color: COLOR.SECONDARY, fontSize: "0.9em" }}>
                ...
              </span>
            </PromoTitle>
            <PromoSubtitle>
              Online Automated Service
              <br />
              Coming Soon.
            </PromoSubtitle>
            <Helmet>
              <link
                href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
                rel="stylesheet"
                type="text/css"
              />
            </Helmet>
            <div id="mc_embed_signup" style={{ padding: 20 }}>
              <form
                action="https://law.us7.list-manage.com/subscribe/post?u=ac10fcc168e8213659360f06f&amp;id=70954954a5"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <input
                    style={{ padding: 20 }}
                    type="email"
                    name="EMAIL"
                    className="email"
                    id="mce-EMAIL"
                    placeholder="email address"
                    required
                  />
                  <Button type="submit" color="secondary" size="small">
                    Subscribe
                  </Button>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_ac10fcc168e8213659360f06f_70954954a5"
                      tabIndex={-1}
                      value=""
                    />
                  </div>
                </div>
              </form>
            </div>
          </HomePromoInner>
        </Container>
      </HomePromoContainer>
    </Curve>
  );
}

export default CommingSoonHeaderPromo;
