import styled from "styled-components";
import { COLOR } from "../../../style";

export const Headline = styled.h1`
  font-size: 26px;
  font-weight: 400;
  color: ${COLOR.DARK};
`;
export const Title = styled.h2`
  font-size: 22px;
  font-weight: 500;
  color: ${COLOR.DARK};
  line-height: 30px;
`;
export const Subheader = styled.header`
  font-size: 18px;
  font-weight: 400;
  color: ${COLOR.DARK};
`;
export const Body2 = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: ${COLOR.DARK};
`;
export const Body1 = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${COLOR.DARK};
`;
export const Caption = styled.caption`
  font-size: 13px;
  font-weight: 400;
  color: ${COLOR.DARK};
`;

export const HeadlineLight = styled(Headline)`
  color: ${COLOR.LIGHT};
`;
export const TitleLight = styled(Title)`
  color: ${COLOR.LIGHT};
`;
export const SubheaderLight = styled(Subheader)`
  color: ${COLOR.LIGHT};
`;
export const Body2Light = styled(Body2)`
  color: ${COLOR.LIGHT};
`;
export const Body1Light = styled(Body1)`
  color: ${COLOR.LIGHT};
`;
export const CaptionLight = styled(Caption)`
  color: ${COLOR.LIGHT};
`;
